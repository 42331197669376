import Link from "next/link";
import Image from "next/image";
import { getCookie } from "cookies-next";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";

import getLanguage from "@/app/utils/getlanguage";
import translation from "./locale/index.json";
import logo from "../../../public/images/logo.png";
import LanguageChooserComponent from "../LanguageChooser";

const CookieKey = "token";

export default function NavBar() {
  const { locale } = useRouter();
  const content = getLanguage(translation, locale);
  const [isLogin, setIsLogin] = useState(false);
  const [mobile, setMobile] = useState(false);

  useEffect(() => {
    setIsLogin(isLogin);
    const cookies = getCookie(CookieKey) || null;
    if (cookies) setIsLogin(true);
  }, [setIsLogin, isLogin]);

  return (
    <div
      className={`top-0 container mx-auto sticky z-[1000] min-h-[2rem] border-[1px] border-t-0 border-white rounded-br-3xl rounded-bl-3xl bg-bluewood text-white`}
    >
      <div className={`text-sm flex flex-row justify-start items-center w-full h-full`}>
        <Link href="/">
          <Image src={logo} alt={"VALAR-AI Logo"} />
        </Link>

        <div className="w-full flex flex-row justify-end items-center pr-5">
          <div className="hidden sm:block w-full">
            <div className="flex flex-row justify-end items-center pr-5">
              <Link href="/cooperation">
                <div className="p-2">{content.cooperation}</div>
              </Link>
              <Link href="/ai-automation-bussiness-tool">
                <div className="p-2">{content.automation}</div>
              </Link>
              {/* <Link href="/medical-assistant">
                <div className="p-2">{content.medicalassistant}</div>
              </Link>
              <Link href="/legal-assistant">
                <div className="p-2">{content.legalassistant}</div>
              </Link> */}
              <Link href="/blog">
                <div className="p-2">{content.blog}</div>
              </Link>
              <Link href="/doc">
                <div className="p-2">{content.doc}</div>
              </Link>
              {!isLogin ? (
                <>
                  <Link href="/auth/login">
                    <div className="p-2">{content.login}</div>
                  </Link>
                  <Link href="/auth/register">
                    <div className="p-2">{content.register}</div>
                  </Link>
                </>
              ) : (
                <>
                  <Link href="/panel">
                    <div className="p-2">{content.panel}</div>
                  </Link>
                </>
              )}
              <LanguageChooserComponent />
            </div>
          </div>

          <div className="sm:hidden cursor-pointer" onClick={() => (mobile ? setMobile(false) : setMobile(true))}>
            Menu
          </div>
          <div className={`${mobile ? "inline" : "hidden"}`}>
            <div className="flex flex-col justify-center items-end pr-5 w-full">
              <Link href="/cooperation">
                <div className="p-2">{content.cooperation}</div>
              </Link>
              <Link href="/ai-automation-bussiness-tool">
                <div className="p-2">{content.automation}</div>
              </Link>
              {/* <Link href="/medical-assistant">
                <div className="p-2">{content.medicalassistant}</div>
              </Link>
              <Link href="/legal-assistant">
                <div className="p-2">{content.legalassistant}</div>
              </Link> */}
              <Link href="/blog">
                <div className="p-2">{content.blog}</div>
              </Link>
              <Link href="/doc">
                <div className="p-2">{content.doc}</div>
              </Link>
              {!isLogin ? (
                <>
                  <Link href="/auth/login">
                    <div className="p-2">{content.login}</div>
                  </Link>
                  <Link href="/auth/register">
                    <div className="p-2">{content.register}</div>
                  </Link>
                </>
              ) : (
                <>
                  <Link href="/panel">
                    <div className="p-2">{content.panel}</div>
                  </Link>
                </>
              )}
              <LanguageChooserComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
