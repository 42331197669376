import Image from "next/image";
import getConfig from "next/config";
import { useRouter } from "next/router";
import translation from "./locale/index.json";
import getLanguage from "@/app/utils/getlanguage";
import Subscribe from "@/app/components/Subscribe";
import SeoHeader from "@/app/components/SeoHeader";
import { replaceDomain } from "@/app/utils/replaceDomain";
import rocket from "@/public/images/rocket.png";
import Footer from "@/app/components/Footer";
import CookiesWidget from "@/app/components/CookiesWidget";
import NotifySite, { CalloutVariant } from "@/app/components/Notify";
import ServiceChat from "@/app/components/Services/Text/Chat";
import NavBar from "@/app/components/NavBar";
// import PlanSubscribe from "@/app/components/PlanSubscribe";

export default function IndexPage(props: { cookiesPP: boolean }) {
  const { cookiesPP } = props;
  const { publicRuntimeConfig } = getConfig();
  const { locale } = useRouter();
  const lang = getLanguage(translation, locale);
  const APP_HOST = publicRuntimeConfig.APP_HOST || "http://localhost:3000";
  const content = lang.content;
  const headers = replaceDomain(lang.headers, APP_HOST, "XXX");

  return (
    <>
      <SeoHeader
        title={headers.title}
        description={headers.description}
        keyWords={headers.keyWords}
        openGraph={headers.openGraph}
        canonicalLink={headers.canonicalLink}
        metaRobots={headers.metaRobots}
        jsonLd={headers.jsonLd}
        // preloadImgLinks={text}
      />

      <section className="min-h-screen min-w-screen" dir={locale === "ar" ? "rtl" : "ltr"}>
        <div className="mx-auto p-2 sm:p-2 md:p-5 lg:p-5 mt-[15%] flex sticky top-[25%] items-center justify-center text-center text-6xl md:text-7xl lg:text-9xl">
          {content.sloganOne}
        </div>
      </section>

      <section className="min-h-[400px] min-w-screen" dir={locale === "ar" ? "rtl" : "ltr"}>
        <div className="mx-auto p-5 flex sticky top-[5%] items-center justify-center text-center text-6xl md:text-7xl lg:text-9xl">{content.sloganTwo}</div>
      </section>

      <NavBar />

      <div className="flex flex-row items-start justify-between z-[-20]">
        <NotifySite info={content.beta} variant={CalloutVariant.default} />
      </div>

      <div className="container mx-auto" dir={locale === "ar" ? "rtl" : "ltr"}>
        <section className="min-w-screen">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 m-2">
            <div className="mx-auto sm:mt-[10%] mr-[5%] w-full">
              <div className="sm:mt-4 text-black-300">
                <ServiceChat />
              </div>
            </div>
            <div>
              <div className="text-2xl p-2 sm:mt-[10rem] font-bold">{content.title}</div>
              <div className="text-xl p-2 font-bold mt-[5%]">{content.smallTitle}</div>
              <div className=" text-lg mt-[5%] p-2">{content.description}</div>
              <div className=" text-lg mt-[5%] p-2">
                <Subscribe />
              </div>
            </div>
          </div>
        </section>

        <section className="min-w-screen">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 m-2">
            <div dir={locale === "ar" ? "rtl" : "ltr"}>
              <div className="text-2xl p-2 sm:mt-[5%] md:mt-[5%] lg:mt-[5%] font-bold">{content.titleOne}</div>
              <div className="text-xl p-2 font-bold mt-[5%] sm:mt-[5%] md:mt-[4%] lg:mt-[4%]">{content.smallTitleOne}</div>
              <div className=" text-lg mt-[2%] p-2">{content.descriptionOne}</div>
              <div className=" text-lg mt-[2%]">
                <ul className="list-disc">
                  <li className="ml-10 p-2">{content.argOne}</li>
                  <li className="ml-10 p-2">{content.argTwo}</li>
                  <li className="ml-10 p-2">{content.argThree}</li>
                  <li className="ml-10 p-2">{content.argFour}</li>
                  <li className="ml-10 p-2">{content.argFive}</li>
                  <li className="ml-10 p-2">{content.argSix}</li>
                  <li className="ml-10 p-2">{content.argSeven}</li>
                  <li className="ml-10 p-2">{content.argEight}</li>
                  <li className="ml-10 p-2">{content.argNine}</li>
                  <li className="ml-10 p-2">{content.argTen}</li>
                </ul>
              </div>
            </div>
            <div className="mx-auto mt-[15%] md-[5%] sm:mt-[35%] ml-[5%]">
              <Image src={rocket} alt={"racket"} priority={true} quality={75} />
            </div>
          </div>
        </section>

        {/* <section className="mt-[2rem] w-full p-1 flex flex-col items-center justify-center">
          <PlanSubscribe />
        </section> */}

        <section className="min-w-screen h-screen">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 m-2">
            <div className="sm:mt-[10%] md:mt-[20%] lg:mt-[20%]">
              <div className="text-lg pr-2 pl-2">{content.descriptionNext2}</div>
              <div className="mt-[5%] sm:mt-[3%] md:mt-[4%] lg:mt-[4%]">
                <Subscribe />
                <div className="mt-5">
                  <ServiceChat />
                </div>
              </div>
            </div>
            <div>
              <div className="pr-5 pl-5 text-2xl mt-10 sm:mt-[10%] md:mt-[10%] lg:mt-[20%] font-bold">{content.titleNext}</div>
              <div className="text-xl font-bold mt-[5%] pr-5 pl-5">{content.smallTitleNext}</div>
              <div className=" text-lg mt-[5%] pr-5 pl-5">{content.descriptionNext}</div>
              <div className="text-lg mt-[1%]">
                <ul className="p-1 list-decimal">
                  <li className="ml-10 p-2">{content.one}</li>
                  <li className="ml-10 p-2">{content.two}</li>
                  <li className="ml-10 p-2">{content.tree}</li>
                </ul>
              </div>
              <div className=" text-lg mt-[5%] pr-5 pl-5">{content.aboutUs}</div>
            </div>
          </div>
        </section>
      </div>
      {!cookiesPP ? <CookiesWidget /> : ""}
      <div className="mt-[85rem] sm:mt-5 md:mt-[6rem] text-white relative bottom-0 w-screen">
        <div className="pointer-events-none flex h-[65px] translate-y-0.5 justify-center overflow-hidden">
          <svg xmlns="http://www.w3.org/2000/svg" width="2500" height="65" viewBox="0 0 2500 65" className="absolute bottom-0 fill-bluewood">
            <path d="M 2019.58 14.03 C 1799.7 28.11 1670.77 106.46 1269.53 30.22 C 952.2 -30.07 684 12.76 408.73 47.37 C 273.12 64.42 130.79 66.63 0 66.86 V 68.86 H 2500 V 66.86 C 2371.07 66.86 2288.68 1.17 2019.58 14.03 Z"></path>
          </svg>
        </div>

        <div className="text-white bg-bluewood">
          <Footer />
        </div>
      </div>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function getServerSideProps(context: any) {
  const cookiesPP = context?.req?.cookies;
  return {
    props: { cookiesPP: cookiesPP["p4u-cookies"] || false }, // will be passed to the page component as props
  };
}
